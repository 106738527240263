import { render, staticRenderFns } from "./PublicReportsPerAccount.vue?vue&type=template&id=5c6a7d7d&scoped=true&"
import script from "./PublicReportsPerAccount.vue?vue&type=script&lang=js&"
export * from "./PublicReportsPerAccount.vue?vue&type=script&lang=js&"
import style1 from "./PublicReportsPerAccount.vue?vue&type=style&index=1&id=5c6a7d7d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c6a7d7d",
  null
  
)

/* custom blocks */
import block0 from "./PublicReportsPerAccount.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports